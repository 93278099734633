import { createMuiTheme } from '@material-ui/core/styles';
import { red, indigo, blueGrey } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
		primary: indigo,
		secondary: blueGrey,
		error: {
			main: red.A400,
		},
	},
});

export default theme;