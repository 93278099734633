import React, { useEffect, useState } from 'react'
import firebase from './utils/firebase'
import { Switch, Route, Link } from 'react-router-dom'
import { Divider, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Typography, Hidden, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import HotelIcon from '@material-ui/icons/Hotel';
import MenuIcon from '@material-ui/icons/Menu';
import ContactsIcon from '@material-ui/icons/Contacts';
import MapIcon from '@material-ui/icons/Map';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddToHomescreen from 'react-add-to-homescreen';

import Loader from './components/Loader';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import User from './pages/User';
import Map from './pages/Map';
import Beds from './pages/Beds';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

export default function App (props) {

    const loc = props.location.pathname.split('/')[1]
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [user,setUser] = useState(undefined)

    const handleAddToHomescreenClick = () => {
        alert(`
          1. Open Share menu
          2. Tap on "Add to Home Screen" button`);
    };

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userSession().then(data => {
                    if (data.rol === "admin" || data.rol === "agent") {
						setUser(data)
                    } else {
                        firebase.logout().then(() => {
							props.history.push('/iniciar-sesion')
						})
                    }
                })
            } else {
				props.history.push('/iniciar-sesion')
			}
        })
    },[props.history])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem selected={loc === '' || loc === undefined ? true : false} component={Link} to="/" button>
                    <ListItemIcon><DashboardIcon/></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
				<ListItem selected={loc === 'camas' ? true : false} component={Link} to="/camas" button>
                    <ListItemIcon><HotelIcon/></ListItemIcon>
                    <ListItemText primary="Camas" />
                </ListItem>
				<ListItem selected={loc === 'usuarios' ? true : false} component={Link} to="/usuarios" button>
                    <ListItemIcon><ContactsIcon/></ListItemIcon>
                    <ListItemText primary="Pacientes" />
                </ListItem>
                <ListItem selected={loc === 'mapa' ? true : false} component={Link} to="/mapa" button>
                    <ListItemIcon><MapIcon/></ListItemIcon>
                    <ListItemText primary="Mapa" />
                </ListItem>
            </List>
        </div>
    )

    return user ? (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ color: '#fff', fontWeight: '700' }} variant="h6" noWrap>
                        Panel de Administrador
                    </Typography>
					<IconButton onClick={logOut} style={{ marginLeft: 'auto' }}>
						<ExitToAppIcon/>
					</IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    <Switch>
                        <Route path="/" component={Dashboard} exact={true} />
                        <Route path="/camas" component={Beds} exact={true} />
                        <Route path="/usuarios" component={Users} exact={true} />
                        <Route path="/usuarios/:id" component={User} exact={true} />
                        <Route path="/mapa" component={Map} exact={true} />
                    </Switch>
                }
            </main>
            <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
        </>
    ) : <Loader />


	async function logOut () {
		try {
			firebase.logout().then(() => {
				props.history.push('/iniciar-sesion')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}