import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase'
import { Container, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@material-ui/core';
import _ from 'lodash';

const Item = ({ data, hospitals }) => {

    const hosp = _.find(hospitals, o => {
        return o.id === data.userId
    })

    return (
        <TableRow>
            <TableCell>{hosp?.name} - {hosp?.address?.formatted_address} - {hosp?.phone}</TableCell>
            <TableCell align="center">{data.number}</TableCell>
            <TableCell align="center"><Checkbox color="primary" checked={data.isAvailable} disabled /></TableCell>
            <TableCell align="center"><Checkbox color="primary" checked={data.isIntensiveTherapy} disabled /></TableCell>
            <TableCell align="center"><Checkbox color="primary" checked={data.haveFan} disabled /></TableCell>
        </TableRow>
    )
}

export default function Beds () {

    const [beds,setbeds] = useState([])
    const [hospitals,sethospitals] = useState([])

    useEffect(() => {
        firebase.getCollection('beds').orderBy('userId').orderBy('number').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setbeds(data)
        })
        firebase.getCollection('users').where('rol','==','hospital').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            sethospitals(data)
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head">Hospital</TableCell>
                        <TableCell variant="head">Número de Cama</TableCell>
                        <TableCell variant="head">Disponible</TableCell>
                        <TableCell variant="head">¿Terapia intensiva?</TableCell>
                        <TableCell variant="head">¿Tiene Ventilador?</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        beds.map(item => <Item hospitals={hospitals} key={item.id} data={item} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}