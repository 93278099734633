import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Container, Table, TableBody, TableRow, TableCell, Grid, Divider, Checkbox, TextField, Select } from '@material-ui/core'
import moment from 'moment'


export default function User (props) {

    const { id } = props.match.params

    const [user,setuser] = useState(null)
    const [hospitals,sethospitals] = useState([])

    useEffect(() => {
        firebase.getDocument('users',id).onSnapshot(snap => {
            const data = snap.data()
            setuser(data)
        })
        firebase.getCollection('users').where('rol','==','hospital').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            sethospitals(data)
        })
    },[id])

    return user ? (
        <Container>
            <h3>DATOS GENERALES</h3>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell>{user.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Teléfono</TableCell>
                        <TableCell>{user.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Edad</TableCell>
                        <TableCell>{user.age}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Genero</TableCell>
                        <TableCell>{user.gender}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Dirección</TableCell>
                        <TableCell>{user.address}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Asignar a Hopital</TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                native
                                value={user.hospital}
                                onChange={e => firebase.update('users',id,'hospital',e.target.value)}
                            >
                                <option value="">** Seleccionar Opción **</option>
                                {
                                    hospitals.map(item => <option key={item.id} value={item.id}>{item.name} - {item.address?.formatted_address} - {item.phone}</option>)
                                }
                            </Select>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Revisado</TableCell>
                        <TableCell><Checkbox checked={user.isAttended} onClick={() => firebase.update('users',id,'isAttended',!user.isAttended)} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Visita a Domicilio</TableCell>
                        <TableCell><Checkbox checked={user.toVisit} onClick={() => firebase.update('users',id,'toVisit',!user.toVisit)} /></TableCell>
                    </TableRow>
                    {
                        user.toVisit ? <TableRow>
                            <TableCell variant="head">Fecha de Visita</TableCell>
                            <TableCell>
                                <TextField
                                    label="Fecha de Visita"
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    defaultValue={moment(user.visitDate).format('YYYY-MM-DD')}
                                    onChange={e => firebase.update('users',id,'visitDate',moment(e.target.value,'YYYY-MM-DD').valueOf())}
                                />
                            </TableCell>
                        </TableRow> : <TableRow></TableRow>
                    }
                    <TableRow>
                        <TableCell variant="head">Resultado de Prueba COVID</TableCell>
                        <TableCell>
                            <Select
                                fullWidth
                                native
                                value={user.status}
                                onChange={e => firebase.update('users',id,'status',e.target.value)}
                            >
                                <option value="">** Seleccionar Opción **</option>
                                <option value="positivo">Positivo</option>
                                <option value="curado">Curado</option>
                                <option value="deceso">Deceso</option>
                            </Select>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <h3>SINTOMAS</h3>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Fiebre</TableCell>
                                <TableCell><Checkbox disabled checked={user.fiebre} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Tos Seca</TableCell>
                                <TableCell><Checkbox disabled checked={user.tos_seca} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Cansancio</TableCell>
                                <TableCell><Checkbox disabled checked={user.cansancio} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Dolor de Garganta</TableCell>
                                <TableCell><Checkbox disabled checked={user.dolor_de_garganta} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Diarrea</TableCell>
                                <TableCell><Checkbox disabled checked={user.diarrea} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Conjuntivitis</TableCell>
                                <TableCell><Checkbox disabled checked={user.conjuntivitis} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Dolor de Cabeza</TableCell>
                                <TableCell><Checkbox disabled checked={user.dolor_de_cabeza} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Pérdida del sentido del olfato</TableCell>
                                <TableCell><Checkbox disabled checked={user.perdida_olfato} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Pérdida del sentido del gusto</TableCell>
                                <TableCell><Checkbox disabled checked={user.perdida_gusto} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Erupciones cutáneas o pérdida del color en los dedos de las manos o de los pies</TableCell>
                                <TableCell><Checkbox disabled checked={user.erupciones_cutaneas} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Dificultad para respirar o sensación de falta de aire</TableCell>
                                <TableCell><Checkbox disabled checked={user.dificultad_respirar} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Dolor o presión en el pecho</TableCell>
                                <TableCell><Checkbox disabled checked={user.dolor_pecho} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Incapacidad para hablar o moverse</TableCell>
                                <TableCell><Checkbox disabled checked={user.incapacidad_moverse} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h3>Enfermedades Crónicas</h3>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Asma</TableCell>
                                <TableCell><Checkbox disabled checked={user.asma} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Hipertensión</TableCell>
                                <TableCell><Checkbox disabled checked={user.hipertension} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Enfermedad renal crónica bajo tratamiento con diálisis</TableCell>
                                <TableCell><Checkbox disabled checked={user.renal} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Enfermedad pulmonar crónica</TableCell>
                                <TableCell><Checkbox disabled checked={user.pulmonar} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Diabetes</TableCell>
                                <TableCell><Checkbox disabled checked={user.diabetes} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Trastornos de hemoglobina</TableCell>
                                <TableCell><Checkbox disabled checked={user.hemoglobina} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">Enfermedad Hepática</TableCell>
                                <TableCell><Checkbox disabled checked={user.hepatica} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    ) : ''
}