import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Container } from '@material-ui/core'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMap = ({ list }) => {
    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{ lat: 25.5666987, lng: -108.4673051 }}
        >
            {props.isMarkerShown && 
                list.map(item => <Marker position={{ lat: item.lat, lng: item.lng }} />)
            }
        </GoogleMap>
    ))
    return (
        <MyMapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCrTl05Ajn7uo9_imbmH6SzslZQk1JW6xs&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    )
}

export default function Map () {

    const [list, setlist] = useState([])

    useEffect(() => {
        firebase.getCollection('users').where('rol','==','user').orderBy('timestamp','desc').onSnapshot(snap => {
            setlist(snap.docs.map(doc => doc.data()))
        })
    },[])

    return (
        <Container>
            <MyMap list={list} />
        </Container>
    )
}