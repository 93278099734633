import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Paper, Grid, Container } from '@material-ui/core'
import { css } from '@emotion/core'
import { ResponsiveContainer, PieChart, Pie, Tooltip, LineChart, CartesianGrid, XAxis, YAxis, Legend, Line } from 'recharts'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment'

export default function Dashboard () {

    const [users,setusers] = useState([])
    const [beds,setbeds] = useState([])

    useEffect(() => {
        firebase.getCollection('users').where('rol','==','user').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setusers(data)
        })
        firebase.getCollection('beds').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setbeds(data)
        })
    },[])

    const total = _.size(users)

    const positivo = _.filter(users, o => {
        return o.status === 'positivo'
    })
    const curado = _.filter(users, o => {
        return o.status === 'curado'
    })
    const deceso = _.filter(users, o => {
        return o.status === 'deceso'
    })

    const hombres = _.filter(users, o => {
        return o.gender === 'Masculino'
    })
    const mujeres = _.filter(users, o => {
        return o.gender === 'Femenino'
    })

    const list = _.map(moment.months(), item => {
        const filt = _.filter(users, o => {
            return moment(o.timestamp).format('MMMM') === item
        })
        const total = _.size(filt)
        return { value: total, name: item }
    });

    const disponibles = _.filter(beds, o => {
        return o.isAvailable === true
    })
    const disponiblesVentilador = _.filter(disponibles, o => {
        return o.haveFan === true
    })
    const disponiblesIntensiva = _.filter(disponibles, o => {
        return o.isIntensiveTherapy === true
    })
    const nodisponibles = _.filter(beds, o => {
        return o.isAvailable !== true
    })

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{total}</b>
                        <h3>Usuarios Registrados</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{_.size(positivo)}</b>
                        <h3>Infectados</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{_.size(curado)}</b>
                        <h3>Curados</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        font-size: 1.2rem;
                        height: 100%;
                        padding-top: .5rem;
                        h3 {
                            margin-top: .5rem;
                            margin-bottom: .5rem;
                        }
                    `}>
                        <b>{_.size(deceso)}</b>
                        <h3>Muertes</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{numeral(_.size(deceso)/total).format('0.00%')}</b>
                        <h3>Porcentaje de Mortalidad</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{numeral(_.size(mujeres)/total).format('0.00%')}</b>
                        <h3>Porcentaje Femenino</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 1.2rem;
                    height: 100%;
                    padding-top: .5rem;
                    h3 {
                        margin-top: .5rem;
                        margin-bottom: .5rem;
                    }
                `}>
                        <b>{numeral(_.size(hombres)/total).format('0.00%')}</b>
                        <h3>Porcentaje Mascullino</h3>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <ResponsiveContainer width="100%" height={350}>
                        <PieChart width={730} height={250}>
                            <Pie data={[ { "name": 'Curados', "value": _.size(curado) }, { "name": 'Positivos', "value": _.size(positivo) }, { "name": 'Decesos', "value": _.size(deceso) }  ]} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={30} outerRadius={80} fill="#82ca9d" label />
                            <Tooltip/>
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={8}>
                    <ResponsiveContainer width="100%" height={350}>
                        <LineChart data={list}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="value" stroke="#8884d8" />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper css={css`
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        justify-content: center;
                        font-size: 1.2rem;
                        height: 100%;
                        padding-top: .5rem;
                        h3 {
                            font-size: 2rem;
                        }
                        b {
                            font-size: 1.8rem;
                        }
                    `}>
                        <b>{_.size(beds)}</b>
                        <h3>Total de Camas</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <ResponsiveContainer width="100%" height={350}>
                            <PieChart width={730} height={250}>
                                <Pie data={[ { "name": 'Camas Disponibles', "value": _.size(disponibles) }, { "name": 'Camas Ocupadas', "value": _.size(nodisponibles) } ]} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={30} outerRadius={80} fill="#82ca9d" label />
                                <Tooltip/>
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} md={6} container spacing={3}>
                        <Grid item xs={12}>
                            <Paper css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                text-align: center;
                                font-size: 1.2rem;
                                height: 100%;
                                padding-top: .5rem;
                                h3 {
                                    margin-top: .5rem;
                                    margin-bottom: .5rem;
                                }
                            `}>
                                <b>{numeral(_.size(disponiblesVentilador)).format('0')}</b>
                                <h3>Disponibles con ventilador</h3>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                text-align: center;
                                font-size: 1.2rem;
                                height: 100%;
                                padding-top: .5rem;
                                h3 {
                                    margin-top: .5rem;
                                    margin-bottom: .5rem;
                                }
                            `}>
                                <b>{numeral(_.size(disponiblesIntensiva)).format('0')}</b>
                                <h3>Disponibles con terapia intensiva</h3>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}