import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import firebase from '../utils/firebase'
import { Container, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Checkbox } from '@material-ui/core'
import moment from 'moment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { css } from '@emotion/core';

const Item = ({ data }) => {

    const fiebre = data.fiebre ? 1 : 0
    const tos_seca = data.tos_seca ? 1 : 0
    const cansancio = data.cansancio ? 1 : 0
    const dolor_de_garganta = data.dolor_de_garganta ? 2 : 0
    const diarrea = data.diarrea ? 2 : 0
    const conjuntivitis = data.conjuntivitis ? 2 : 0
    const dolor_de_cabeza = data.dolor_de_cabeza ? 2 : 0
    const perdida_olfato = data.perdida_olfato ? 3 : 0
    const perdida_gusto = data.perdida_gusto ? 3 : 0
    const erupciones_cutaneas = data.erupciones_cutaneas ? 3 : 0
    const dificultad_respirar = data.dificultad_respirar ? 3 : 0
    const dolor_pecho = data.dolor_pecho ? 3 : 0
    const incapacidad_moverse = data.incapacidad_moverse ? 3 : 0

    const sickPoints = fiebre + tos_seca + cansancio + dolor_de_garganta + diarrea + conjuntivitis + dolor_de_cabeza + perdida_olfato + perdida_gusto + erupciones_cutaneas + dificultad_respirar + dolor_pecho + incapacidad_moverse

    const asma = data.asma ? 1 : 0
    const hipertension = data.hipertension ? 1 : 0
    const renal = data.renal ? 1 : 0
    const pulmonar = data.pulmonar ? 1 : 0
    const diabetes = data.diabetes ? 1 : 0
    const hemoglobina = data.hemoglobina ? 1 : 0
    const hepatica = data.hepatica ? 1 : 0

    const cronicPoints = asma + hipertension + renal + pulmonar + diabetes + hemoglobina + hepatica

    return (
        <TableRow css={css`
            td {
                background-color: ${data.status === 'curado' ? '#262626' : data.status === 'deceso' ? '#D95959' : data.status === 'positivo' ? '#B8C9D9' : sickPoints <= 2 ? '#B7D669' : sickPoints > 2 && sickPoints <= 5 ? '#F0C755' : sickPoints > 5 ? '#901811' : ''};
                color: ${data.status === 'curado' ? '#fff' : ''};
            }
        `}>
            <TableCell css={css`
                text-align: center;
            `}><Checkbox checked={data.isAttended} disabled /></TableCell>
            <TableCell>{moment(data.timestamp).format('DD-MM-YYYY HH:mm')}</TableCell>
            <TableCell>{data.name}</TableCell>
            <TableCell>{data.address}</TableCell>
            <TableCell>{data.age}</TableCell>
            <TableCell><IconButton color="primary" component={Link} to={`/usuarios/${data.id}`}><ArrowForwardIosIcon/></IconButton></TableCell>
        </TableRow>
    )
}

export default function Users () {

    const [list, setlist] = useState([])

    useEffect(() => {
        firebase.getCollection('users').where('rol','==','user').orderBy('timestamp','desc').onSnapshot(snap => {
            setlist(snap.docs.map(doc => doc.data()))
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head">¿Revisado?</TableCell>
                        <TableCell variant="head">Fecha de Regístro</TableCell>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell variant="head">Dirección</TableCell>
                        <TableCell variant="head">Edad</TableCell>
                        <TableCell variant="head">Ver</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.map(item => <Item data={item} key={item.id} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}